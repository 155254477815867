.HoloPlayer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: 100dvh;
    width: 100%;
    padding-bottom: calc(env(safe-area-inset-bottom));
}

:root {
    --safeareainsetbottom: env(safe-area-inset-bottom);
}
